/* .homepage {
  /* background-color: black; */
/* height: 300px; */
/* } */

/* .item {
  display: flex;
  align-items:center
  justify-content: center;
} */

/* /* .item-1 {
/* } */
/* .homepage.secondblock {
  background-color: rgb(207, 10, 10);
} */
.homepage {
  background-color: white;
}
