.container {
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
}

.about {
  display: flex;
  align-items: center;
}

.about-inner {
  display: flex;
  gap: 60px;
}

.about-stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-stats-item span {
  display: block;
}

.left-block h2 {
  font-size: 50px;
}

.about-stats-item h3 {
  font-size: 60px;
  line-height: 0;
}

@media (max-width: 1400px) {
  .right-block {
    display: none;
  }
  .about {
    height: 50%;
    text-align: justify;
  }
  /* .about-inner {
    justify-content: center;
  } */
  .about-inner {
    display: flex;
    gap: 30px;
  }
  /* .about-stats-item h3 {
    font-size: 40px;
  } */
}

@media (max-width: 600px) {
  .about-inner {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .about-stats-item h3 {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .about-inner {
    flex-wrap: wrap;
  }
}
