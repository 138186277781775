.person {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  gap: 100px;
  padding-bottom: 150px;
  /* box-shadow: inset 0 0 100px rgb(0 0 0 / 10%); */
}
.staffTitle {
  font-size: 50px;

  padding-top: 50px;
  margin-left: 50px;
}

.photo {
  width: 300px;
  border-radius: 50%;
}

.personWidth {
  flex-basis: 400px;
  height: 444px;
}

@media (max-width: 900px) {
  .staffTitle {
    text-align: center;
    margin-left: 0;
  }
}
