.privacyTersm {
  max-width: 1000px;
  margin: 50px auto 100px;
  padding: 0 auto;

  /* display: flex;
  flex-direction: column; */
}

.privacyTitle {
  text-align: center;
}

.privacyInner {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.privacyInner__item {
  display: flex;
  gap: 3px;
}

.list__items {
  list-style-type: none;
  counter-reset: roman-counter;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: 10px;
}

.list__items li {
  counter-increment: roman-counter;
}

.list__items li:before {
  content: counter(roman-counter, upper-roman) ". ";
}
