.box-shadow {
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.1);
}

.information-main {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 20px;
  gap: 50px;
  height: 100vh;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 800px;
}

.right-information {
  display: flex;
  flex-direction: column;
}

.left-information {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.leftContainer {
  display: flex;
  gap: 50px;
  margin-top: 25px;
}
.rightContainer {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}
.left-information h1 {
  margin-top: 0;
  padding-left: 30;
  font-size: 40px;
}
@media (max-width: 1400px) {
  .information-main {
    flex-direction: column;
    height: 100%;
  }
  .right-information {
    align-items: center;
    max-width: 650px;
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .left-information {
    padding-top: 100px;
    margin-left: 0;
  }
}
@media (max-width: 700px) {
  .leftContainer,
  .rightContainer {
    flex-direction: column;
  }
}
