.main-footer {
  /* background-color: rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;

  padding-top: 20px;
  box-shadow: inset 0 0 100px rgb(0 0 0 / 10%);

  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); */
}

.main-footer-block {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
}
.tag {
  margin-bottom: 0;
}

.footer-link {
  display: flex;
  gap: 30px;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  list-style: none;
}

.footer-telegram-icon {
  height: 50%;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(100%);
}

.footer-telegram-icon img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 40;
  background-color: blue;
}

.footer-button span {
  font-size: 15px;
  color: white;
  margin-left: 5px;
}

.footer-telegram-icon a:hover > * {
  opacity: 0.5;
}
/* 
ul {
  list-style: none;
  list-style-image: width(1), url("/src/assets/images/homepage.png");
} */
a {
  text-decoration: none;
}
a {
  color: black;
}
a:visited {
  color: black;
}

a:hover {
  color: red;
}

.footer-link-icon {
  width: 30px;
  height: 30px;
  padding: 0;
}

.footer-icon {
  transform: translateY(35%);
  height: 70px;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .footer-block-item {
    list-style: none;
    padding: 0;
  }
}

@media (max-width: 500px) {
  .footer-link {
    display: flex;
    gap: 10px;
  }

  .footer-icon {
    height: 50px;
    transform: translateY(50%);
  }
  .footer-link-icon {
    width: 20px;
    height: 20px;
  }
}
