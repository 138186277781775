.main-whatwedo {
  background-color: black;
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.main-whatwedo-aside {
  background-color: black;
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.main-whatwedo-section {
  display: block;
}

.main-whatwedo-section span {
  display: block;
  width: 200px;
  height: 60px;
  text-align: center;
  color: white;
  margin-bottom: 60px;
}

.main-whatwedo-section h4 {
  margin-top: 0;
  margin-bottom: 5px;
  color: white;
}
