.main-section {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* width: 100%; */
}

.main-section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-section .main-section-title {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.main-section-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-section h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  text-align: center;
  color: white;
  font-size: 80px;
  font-weight: 30px;
  font-family: "AcrobatExtraBold";
  max-width: 700px;
}

@media (max-width: 700px) {
  .main-section h1 {
    font-size: 40px;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from {
    border-color: transparent;
  }
  to {
    border-color: transparent;
  }
  10% {
    border-color: white;
  }
} ;
