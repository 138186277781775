.App-header {
  position: absolute;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #e5e5e5;
  transition: 0.1s;
  z-index: 10;
}

.menu {
  box-sizing: border-box;
  text-decoration: none;
  font-size: 20px;
  width: 1000px;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
@media (max-width: 500px) {
  .menu {
    display: none;
  }
}

.menu a {
  text-decoration: none;
}

.logon img {
  height: 70px;
  padding-top: 10px;
  padding-left: 30px;
}

.search img {
  height: 20px;
  width: auto;
  margin-right: 70px;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.dropdown {
  position: relative;
  display: none;
  margin-right: 20px;
  border: 0px;
}

.menu-drop {
  position: absolute;
  list-style-type: none;
  margin: 50px 0px;
  padding: 0;
  width: 80px;
  /* height: 50px; */
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menu-item {
  padding: 5px;
  margin: 10px;
  font: inherit;
  cursor: pointer;
}

.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 700px) {
  .dropdown {
    display: flex;
  }

  .menu {
    display: none;
  }
}
