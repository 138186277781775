.main-section {
  display: flex;
  justify-content: center;
}

.container {
  border: 1px;
  margin: 0px auto;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 800px;
}

/* .container-slider {
} */
.container-slider img {
  height: 500px;
  opacity: 1;
  transition: all ease 1s;
}
/* .container-slider-opasity0 {
  opacity: 0;
} */
