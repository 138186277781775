/* .main-block {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: white;
  height: 100vh;
  align-items: center;
  min-height: 800px;
}
.right-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
} */
/* 
.left-block h2 {
  font-size: 50px;
  margin: 10px 0;
}

.left-block {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 50px;
}

.about-stats {
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
}
.about-stats-item h3 {
  font-size: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
} */

/* .bottom-text {
  position: relative;
  flex-direction: column;
  justify-content: right;
}

.bottom-text h4 {
  font-size: 20px;
  display: flex;
  justify-content: right;
  margin-right: 200px;
}

.bottom-text p {
  font-size: 15px;
  display: flex;
  justify-content: right;
  margin-right: 80px;
} */

.right-block img {
  float: right;
  width: 80%;
}

@media (max-width: 900px) {
  .left-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px;
  }
  .right-block {
    display: none;
  }
}

@media (max-width: 600px) {
  .main-block {
    height: auto;
  }
}
