.projects {
  margin-bottom: 100px;
}
.projects__title {
  font-size: 50px;
  /* padding-top: 50px; */
  margin-left: 50px;
}

.projects__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
}
.projects__inner-item:hover .projects__img {
  box-shadow: 2px 2px 2px grey;
  transition: linear 0.3s;
}

.projects-inner__title {
  margin: 0;
  font-size: 25px;
}

.projects-inner__text {
  color: black;
  max-width: 350px;
  margin: 0;
}
.projects__inner-item:hover .projects-inner__title {
  color: blue;
  transition: linear 0.3s;
}

.projects__inner-item {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.projects__img {
  max-width: 350px;
  border-radius: 10px;
}

@media (max-width: 750px) {
  .projects__title {
    margin-left: 0;
    text-align: center;
  }
}
