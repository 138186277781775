.project p,
.project h1,
.project h2,
.project h3 {
  margin: 0;
  padding: 0;
}

.project ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.project li {
  margin: 0;
  padding: 0;
}

.project-container {
  max-width: 1020px;
  padding: 0 10px;
  margin: 0 auto;
}

.project {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.btn-back {
  position: -webkit-sticky;
  position: sticky;
  width: 30px;
  top: 100px;
  left: 20px;
  opacity: 0.3;
}

.btn-back:hover {
  opacity: 1;
  color: black !important;
  transition: linear 0.3s;
}

.btn-svg {
  padding: 10px;
  border-radius: 50%;
  border: 1px solid black;
}

.project__title {
  margin-top: 10px !important;
  text-align: center;
}

.project__text {
  text-align: center;
  padding: 0 10px !important;
}

.project__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.project__list-title {
  margin-bottom: 20px !important;
}

.project__list-item {
  width: 350px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid grey;
  /* justify-content: center; */
  /* justify-content: space-between; */
  align-items: center;
}

.list-item__text {
  padding-bottom: 5px !important;
}

.challenge_to-do {
  margin: 60px 0;
}

.to-do__title {
  margin-bottom: 20px !important;
}

.what__Done {
  margin-bottom: 60px !important;
}

.what__Done-title {
  margin-bottom: 20px !important;
}

.delivered {
  margin-bottom: 60px !important;
}

.delivered-title {
  margin-bottom: 20px !important;
}

.stacks__used {
  margin-bottom: 60px !important;
}

.stacks__used-title {
  margin-bottom: 20px !important;
}

.resources__used {
  margin-bottom: 60px !important;
}

.resources__used-title {
  margin-bottom: 20px !important;
}

.app-body {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 60px !important;
}

.app {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: black;
  color: white !important;
  font-weight: bold;
}

.app:hover {
  color: black !important;
  transition: linear 0.3s;
  background-color: white;
}

@media (max-width: 800px) {
  .btn-back {
    display: none;
  }
}

@media (max-width: 740px) {
  /* .project__list-item {
    flex-basis: 100%;
  } */
  .project__list {
    justify-content: center;
  }
}
