/* *,
*::after,
*::before {
  padding: 0;
  margin: 0;
} */

@font-face {
  font-family: "AcrobatLight";
  src: url("./fonts/Akrobat-Light.otf");
}

@font-face {
  font-family: "AcrobatBlackkz2";
  src: url("./fonts/Akrobat-Black_kz\ 2.otf");
}
@font-face {
  font-family: "AcrobatBlack";
  src: url("./fonts/Akrobat-Black.otf");
}
@font-face {
  font-family: "AcrobatBlackkz";
  src: url("./fonts/Akrobat-Bold_kz.otf");
}
@font-face {
  font-family: "AcrobatBold";
  src: url("./fonts/Akrobat-Bold.otf");
}
@font-face {
  font-family: "AcrobatExtraBold";
  src: url("./fonts/Akrobat-ExtraBold.otf");
}
@font-face {
  font-family: "AcrobatExtraLight";
  src: url("./fonts/Akrobat-ExtraLight.otf");
}
@font-face {
  font-family: "AcrobatSemiBold";
  src: url("./fonts/Akrobat-SemiBold.otf");
}
@font-face {
  font-family: "AcrobatSemiBoldkz";
  src: url("./fonts/Akrobat-SemiBold_kz.otf");
}
@font-face {
  font-family: "AcrobatThin";
  src: url("./fonts/Akrobat-Thin.otf");
}
@font-face {
  font-family: "AcrobatRegular";
  src: url("./fonts/Akrobat-Regular.otf");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "AcrobatLight", "AcrobatBlackkz2", "AcrobatBlack",
    "AcrobatBlackkz", "AcrobatBold", "AcrobatExtraBold", "AcrobatExtraLight",
    "AcrobatSemiBold", "AcrobatSemiBoldkz", "AcrobatThin", "AcrobatRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 247, 247);
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
