.main-stplan-sec {
  background-color: red;
  margin-left: 50px;
  margin-right: 50px;
}
.second-stplan-sec {
  margin-left: 50px;
  margin-right: 50px;
}

.column-boxes {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}
.column-boxes-items {
  border: solid;
  width: 200px;
  margin-top: 30px;
}

.column-boxes-items span {
  background-color: black;
  color: white;
  display: block;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid black;
}
.arrow-icon {
  background-color: white;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.arrow-icon img {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.boxes-aside-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 60px;
}

.boxes-aside-item {
  border: solid;
  width: 150px;
  margin-top: 30px;
}

.boxes-aside-item p {
  flex: 1 1;
}

.boxes-aside-item span {
  background-color: black;
  color: white;
  display: block;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid black;
}
